<template>
  <div>
 <b-icon class="icon" :icon="name" style="vertical-align: middle;"></b-icon>
  </div>
  
</template>

<script>
export default {
  props: {
    name: {},
    modifier: {
      default: null,
    },
    fixAlign: {
      default: true,
    },
  }
}
</script>

<style lang="scss" scoped>
.icon {
  width: 20px;
  height: 20px;
}
</style>