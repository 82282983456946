import { render, staticRenderFns } from "./ICON.vue?vue&type=template&id=42fd2bee&scoped=true"
import script from "./ICON.vue?vue&type=script&lang=js"
export * from "./ICON.vue?vue&type=script&lang=js"
import style0 from "./ICON.vue?vue&type=style&index=0&id=42fd2bee&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42fd2bee",
  null
  
)

export default component.exports